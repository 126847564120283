.fs-8 {
    font-size: 80%;
}

.mln-1 {
    margin-left: -1rem;
}

.divider {
    margin-bottom: 50px;
}

.report_title {
    text-align: center;
    padding-bottom: 20px;
}