.report_card_container p {
  font-weight: normal !important;
}

.data_field {
  margin-right: 5rem;
}

.info_circle {
  position: relative;
  top: -0.14rem;
  cursor: pointer;
  color: #a5a5a5 !important;
}
