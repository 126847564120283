.progress_container {
  position: relative;
}

.progress_column {
  z-index: 2;
  height: 16px;
  border-left: 3px solid #ebebeb;
  border-right: 3px solid #ebebeb;
}

.progress_scale {
  position: absolute;
  z-index: 1;
  height: 16px !important;
  background-color: #fff !important;
  padding: 0 !important;
}